.widget-threecol {
  margin-bottom: clamp(30px, 5vw, 50px);
  border-radius: 10px;
  background-color: #F7F7F7;
  padding: clamp(30px, 6.5dvw, 65px) clamp(20px, 4dvw, 40px);
}
.widget-threecol--gold {
  background-color: var(--color-gold-grey);
}
.widget-threecol__columns {
  display: flex;
  justify-content: space-between;
}
.widget-threecol__columns__col {
  font-size: clamp(14px, 1.5vw, 15px);
  flex: none;
  width: calc(33.333% - 12px);
}
.widget-threecol__columns__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .widget-threecol__columns {
    display: block;
  }
  .widget-threecol__columns__col {
    width: 100%;
  }
  .widget-threecol__columns__col + .widget-threecol__columns__col {
    margin-top: 24px;
  }
}