@import "./shared/site-vars.css";
html {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100vw;
  min-height: 100%;
  min-width: 320px;
  position: relative;
  -webkit-overflow-scrolling: auto;
}

.contentspacer {
  flex: 1 1 auto;
}

.webshop-pagetitle {
  margin-top: 30px;
  color: #fff;
  text-align: center;
  font-size: clamp(50px, 9.6vw, 96px);
}

header, main, footer {
  flex: none;
}

.site-fadeintitle {
  position: relative;
  transition: opacity 700ms 200ms, transform 700ms ease-out 100ms;
  transform: translateY(200px);
  opacity: 0;
}
.site-fadeintitle--visible {
  transform: scale(1);
  opacity: 1;
}

.site-fadeinblock {
  position: relative;
  transition: opacity 700ms 50ms, transform 600ms ease-out 100ms;
}
.site-fadeinblock--hidden {
  transform: scale(0.6);
  opacity: 0;
}
.site-fadeinblock--visible {
  transform: scale(1);
  opacity: 1;
}

.sharepage {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 30px;
}
.sharepage__buttons {
  display: flex;
  align-items: center;
}
.sharepage__label {
  font-family: var(--font-heading);
  margin-right: 10px;
  font-size: clamp(16px, 1.6vw, 18px);
}
.sharepage__button {
  flex: none;
  margin-left: 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: flex;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms;
}
.sharepage__button:hover {
  color: var(--color-red);
}

.pageintroblock {
  position: relative;
  margin-top: clamp(-190px, -19dvw, 0px);
  border-radius: 10px;
  margin-bottom: clamp(30px, 5dvw, 50px);
  box-shadow: 0 7px 29px 0 rgba(100, 100, 111, 0.2);
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
html.webshop--isproductpage .pageintroblock {
  margin-top: clamp(1px, 7dvw, 100px);
}
.pageintroblock__koekje {
  position: absolute;
  right: clamp(20px, 3dvw, 50px);
  top: clamp(-120px, -10dvw, -30px);
  transform: rotate(20deg);
  width: clamp(90px, 21dvw, 250px);
}
.pageintroblock__koekje img {
  position: relative;
  width: 100%;
  height: auto;
  animation: 10s ease 0s infinite beat;
}

.contentpanel, .webshop-standardpage, main > .wh-wrdauth-form {
  background-color: #fff;
  margin-top: clamp(20px, 3.5vw, 35px);
}

.webshop-pagetitle {
  align-self: start;
}

.webshop-standardpage, main > .wh-wrdauth-form {
  padding: clamp(20px, 3vw, 30px);
}

.cookiebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 -7px 15px 0 rgba(102, 102, 102, 0.2);
  border-top: 1px solid #e3e3e3;
  transition: max-height 500ms, opacity 500ms;
  max-height: 0;
  overflow: hidden;
}
.cookiebar--visible {
  max-height: 100dvh;
  overflow-y: auto;
}
.cookiebar__wrapper {
  display: flex;
  justify-content: space-between;
  padding: clamp(20px, 2.4dvw, 24px) 0;
}
.cookiebar__image {
  flex: none;
  width: clamp(75px, 12.5dvw, 125px);
  margin-right: 24px;
  margin-bottom: 10px;
}
.cookiebar__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.cookiebar__content {
  flex: 1;
  font-size: 14px;
  position: relative;
}
.cookiebar__content > *:last-child {
  margin-bottom: 0;
}
.cookiebar__buttons {
  padding-left: 24px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.cookiebar__buttons .button + .button {
  margin-top: 15px;
}
@media (max-width: 620px) {
  .cookiebar__wrapper {
    flex-flow: column;
  }
  .cookiebar__buttons {
    padding-left: 0;
    padding-top: 15px;
    flex-flow: row;
  }
  .cookiebar__buttons .button + .button {
    margin-top: 0;
    margin-right: 15px;
    order: -1;
  }
}
.cookiebar__close {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 5px;
}