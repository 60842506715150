.horizontaltiles__container {
  overflow: hidden;
  position: relative;
}
.horizontaltiles__container--overflow::after, .horizontaltiles__container--overflow::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 100%;
  background-color: inherit;
  pointer-events: none;
}
.horizontaltiles__container::after {
  left: auto;
  right: 0;
}
.horizontaltiles__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  transition: transform 0.3s;
}
.horizontaltiles__nav {
  margin-top: 10px;
  display: hidden;
  justify-content: center;
  align-items: center;
}
.horizontaltiles__nav button {
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  text-align: center;
  line-height: 21px;
  font-size: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid var(--color-grey-dark6a);
  transition: opacity 0.3s;
}
.horizontaltiles__nav button[data-action=pause] {
  font-size: 10px;
}
.horizontaltiles__nav button[disabled] {
  opacity: 0.3;
  cursor: default;
}
.horizontaltiles__container--overflow + .horizontaltiles__nav {
  display: flex;
}