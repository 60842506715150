.widget-image {
  margin-bottom: 30px;
}
.widget-image__image {
  width: 100%;
}
.widget-image__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 10px;
}
.widget-image__caption {
  margin-top: 10px;
}
.widget-image__caption > *:last-child {
  margin-bottom: 0;
}