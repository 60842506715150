.wh-autocomplete-values {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 0 0 10px 10px;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid var(--color-grey-lighter);
  z-index: 1;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
  padding: 5px 0 !important;
}
.wh-autocomplete-values > li {
  line-height: 150%;
  cursor: pointer;
  color: var(--color-black);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 15px;
}
.wh-autocomplete-values > li.noresults {
  font-style: italic;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  text-shadow: 0 0 1px #000;
}
.wh-autocomplete-values > li emp, .wh-autocomplete-values > li i {
  font-weight: 400;
  color: var(--color-red);
}