.contentheader {
  position: relative;
  z-index: 0;
  margin-top: -132px;
  min-height: 200px;
  color: #fff;
  margin-bottom: 50px;
}
@media (max-width: 900px) {
  .contentheader {
    margin-top: -125px;
  }
}
@media (max-width: 800px) {
  .contentheader {
    margin-top: -118px;
  }
}
@media (max-width: 700px) {
  .contentheader {
    margin-top: -110px;
  }
}
.contentheader__image {
  position: relative;
}
.contentheader__image__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.contentheader__image__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contentheader__imagebanner {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  max-width: 100%;
}
.contentheader--panelbackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.contentheader--introblockbelow {
  min-height: clamp(350px, 55dvw, 550px);
  padding-bottom: clamp(1px, 15dvw, 150px);
}
.contentheader__image, .contentheader__videoholder {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.contentheader__image::before, .contentheader__videoholder::before {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.17);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.contentheader__image__mask, .contentheader__videoholder__mask {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: clamp(15px, 5vw, 50px);
}
.contentheader__videoholder {
  background-color: #333;
  overflow: hidden;
}
.contentheader__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
}
@media (max-width: 620px) {
  .contentheader__video {
    height: 100%;
    width: auto;
  }
}
.contentheader__video > iframe {
  height: 100%;
  width: 100%;
}
.contentheader__image.videoloaded .contentheader__image__background {
  display: none;
}
.contentheader__product {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 185px;
}
.contentheader__product img {
  position: relative;
  width: 100%;
  height: auto;
  animation: 2.5s ease 0s infinite beat;
}
.contentheader__content {
  padding: clamp(120px, 18dvw, 180px) 0 24px;
  height: 100%;
  min-height: 330px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 768px) {
  .contentheader__content {
    min-height: 0;
  }
}
.contentheader__contentwrapper {
  height: 100%;
}
.contentheader__content > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .contentheader:not(.contentheader--introblockbelow) .contentheader__content > h1, .contentheader:not(.contentheader--introblockbelow) .contentheader__content > p.intro {
    display: none;
  }
}

.mobileheading1 {
  margin-bottom: 30px;
}

.mobileheading1 + .mobilesubtitle {
  margin-top: -30px;
}

.mobilesubtitle {
  margin-bottom: 30px;
}

.mobileheading1, .mobilesubtitle {
  display: none;
}
@media (max-width: 768px) {
  .mobileheading1, .mobilesubtitle {
    display: block;
  }
}