.widget-buttons {
  margin-bottom: 50px;
  margin-left: -12px;
  display: flex;
  flex-wrap: wrap;
}
.widget-buttons--center {
  justify-content: center;
}
.widget-buttons--right {
  justify-content: flex-end;
}
.widget-buttons--vertical {
  flex-direction: column;
  align-items: flex-start;
}
.widget-buttons--vertical.widget-buttons--center {
  align-items: center;
}
.widget-buttons--vertical.widget-buttons--right {
  align-items: flex-end;
}
.widget-buttons .button {
  margin-left: 12px;
  margin-bottom: 12px;
}