.structuredbreadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  align-items: center;
  padding: 5px 20px;
  color: var(--color-grey-dark76);
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid var(--color-divider);
}
.structuredbreadcrumb-wrapper {
  margin-top: 50px;
}
.structuredbreadcrumb-inner {
  position: relative;
  min-height: 42px;
}
.structuredbreadcrumb li {
  display: inline-block;
}
.structuredbreadcrumb li[hidden]::after {
  content: "...";
  display: inline-block;
}
.structuredbreadcrumb li[hidden] > * {
  display: none;
}
.structuredbreadcrumb li[hidden] + li[hidden] {
  display: none;
}
.structuredbreadcrumb li + li::before {
  font-size: 10px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  padding: 0 10px;
}
.structuredbreadcrumb a {
  color: inherit;
  text-decoration: none;
  text-underline-position: under;
}
.structuredbreadcrumb a:hover {
  text-decoration: underline;
}
.structuredbreadcrumb--collapsed {
  cursor: pointer;
}
.structuredbreadcrumb--collapsed.structuredbreadcrumb--foldout {
  cursor: default;
  flex-wrap: wrap;
}
.structuredbreadcrumb--collapsed.structuredbreadcrumb--foldout li[hidden] {
  white-space: initial;
}
.structuredbreadcrumb--collapsed.structuredbreadcrumb--foldout li[hidden] > *, .structuredbreadcrumb--collapsed.structuredbreadcrumb--foldout li[hidden] + li[hidden] {
  display: inline-block;
}
.structuredbreadcrumb--collapsed.structuredbreadcrumb--foldout li[hidden]::after {
  display: none;
}

.footer {
  margin-top: 15px;
  background-color: var(--color-grey-dark25);
  font-family: var(--font-heading);
  font-size: 18px;
  line-height: 135%;
  color: #fff;
}
.footer__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  background-color: #fff;
}
.footer a {
  color: inherit;
  text-underline-position: under;
}
.footer__content {
  padding: clamp(30px, 6dvw, 60px) 0;
  display: flex;
  justify-content: space-between;
}
.footer__content__left {
  flex: none;
  width: 25%;
  min-width: 242px;
}
.footer__content__right {
  flex: none;
  padding-top: 32px;
  width: calc(75% - 20px);
  max-width: calc(100% - 262px);
  display: flex;
  justify-content: space-between;
}
@media (max-width: 850px) {
  .footer__content__left {
    width: 33.333%;
    min-width: 202px;
  }
  .footer__content__right {
    width: calc(66.666% - 20px);
    max-width: calc(100% - 222px);
    display: block;
  }
}
@media (max-width: 470px) {
  .footer__content {
    display: block;
  }
  .footer__content__left {
    width: 100%;
  }
  .footer__content__right {
    width: 100%;
    max-width: 100%;
    padding-top: 20px;
  }
}
.footer__logo {
  display: inline-block;
  text-decoration: none;
  width: clamp(202px, 24.2dvw, 242px);
  margin-bottom: 30px;
}
@media (max-width: 470px) {
  .footer__logo {
    margin-bottom: 0;
  }
}
.footer__logo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.footer__socialmedia__title {
  font-weight: 300;
  margin-bottom: 10px;
}
.footer__socialmedia__icon {
  width: 28px;
}
.footer__socialmedia__icon > svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  fill: #fff;
}
.footer__socialmedia ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 28px;
  align-items: center;
}
.footer__socialmedia ul li + li {
  margin-left: 15px;
}
.footer__socialmedia--mobile {
  display: none;
  align-items: center;
  margin-right: 10px;
}
.footer__socialmedia--mobile ul {
  font-size: 24px;
}
.footer__socialmedia--mobile ul li + li {
  margin-left: 10px;
}
.footer__socialmedia--mobile .footer__socialmedia__title {
  margin-bottom: 0;
  margin-right: 10px;
}
@media (max-width: 470px) {
  .footer__socialmedia--desktop {
    display: none;
  }
  .footer__socialmedia--mobile {
    display: flex;
  }
}
.footer__nav {
  flex: none;
  width: calc(33.333% - 18px);
}
.footer__nav__heading {
  font-size: 22px;
  margin-bottom: 10px;
}
@media (min-width: 851px) {
  .footer__nav__heading.wh-details__summary {
    cursor: default;
  }
  .footer__nav__container.wh-details__container {
    display: block;
    overflow: visible;
    transition: height 0s;
    content-visibility: visible;
    height: auto;
  }
}
@media (max-width: 850px) {
  .footer__nav {
    width: 100%;
  }
  .footer__nav__heading.wh-details__summary::before {
    font-family: "Font Awesome 5 Pro" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 15px;
    position: relative;
    top: -3px;
    font-size: 13px;
    content: "\f067";
  }
  .footer__nav.wh-details--open .footer__nav__heading.wh-details__summary::before {
    content: "\f068";
  }
  .footer__nav + .footer__nav {
    margin-top: 15px;
  }
}
.footer__nav p, .footer__nav ul {
  font: 15px var(--font-default);
}
.footer__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 850px) {
  .footer__nav ul {
    padding-bottom: 15px;
  }
}
.footer__nav ul li {
  margin-top: 10px;
}
.footer__nav a:not(.button) {
  text-decoration: none;
  color: inherit;
}
.footer__nav a:not(.button):hover {
  text-decoration: underline;
}
.footer__bottombar {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
}
.footer__bottombar__copyright {
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
}
.footer__bottombar__right i {
  color: var(--color-red);
}
.footer__bottombar__right a:not(.button) {
  text-decoration: underline;
}
@media (max-width: 650px) {
  .footer__bottombar {
    flex-flow: column;
    align-items: center;
  }
  .footer__bottombar__right {
    margin-top: 15px;
  }
}
@media (max-width: 470px) {
  .footer__bottombar__left {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}