.widget-highlights {
  border-radius: 10px;
  background-color: #F7F7F7;
  padding: clamp(20px, 3.5dvw, 35px);
}
.widget-highlights--gold {
  background-color: var(--color-gold-grey);
}
.widget-highlights h2 {
  font-size: clamp(28px, 4.4dvw, 44px);
  font-weight: 500;
  padding-right: clamp(70px, 25dvw, 250px);
}
.widget-highlights > p {
  font-family: var(--font-intro);
  margin: 10px 0 0;
  color: var(--color-grey-dark6a);
  font-style: italic;
  font-size: clamp(20px, 2.5dvw, 25px);
}
.widget-highlights .widget-highlights__list {
  padding: clamp(20px, 2.5dvw, 25px) 12px;
}
.widget-highlights__listholder {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #F7F7F7;
}
@media (max-width: 620px) {
  .widget-highlights__listholder {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.widget-highlights--gold .widget-highlights__listholder {
  background-color: var(--color-gold-grey);
}
.widget-highlights__item {
  flex: none;
  width: 33.333%;
  padding: 0 12px;
}
.widget-highlights__item__link {
  height: 100%;
  min-height: 470px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(102, 102, 102, 0.2);
  border: 1px solid #e3e3e3;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: column;
}
.widget-highlights__item__image {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: auto;
  flex: none;
}
.widget-highlights__item__title {
  flex: none;
  padding: 20px 17px 15px;
}
.widget-highlights__item__description {
  flex: 1;
  padding: 0 17px;
  font-size: 15px;
  color: var(--color-grey-dark6a);
  margin-bottom: 0;
}
.widget-highlights__item__buttonholder {
  flex: none;
  padding: 20px 17px;
}
@media (max-width: 1000px) {
  .widget-highlights__item {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .widget-highlights__item {
    width: 100%;
  }
}