
:root, .wh-rtd-editor
{
  --color-white: #FFF;
  --color-black: #000;
  --color-red-light: #EFC7C9;
  --color-red: #C2203F;
  --color-green: #5C8626;
  --color-gold: #946711;
  --color-yellow: #EE9D00;

  --color-gold-grey: #F6F4EF;

  --color-divider: #bfbfbf;

  --color-grey-dark76: #767676;
  --color-grey-dark6a: #6A6A6A;
  --color-grey-dark25: #252525;
  --color-grey-dark: #3A3A3A;
  --color-grey-light: #DFDAD5;
  --color-grey-lighter: #F7F7F7;

  --font-heading: 'Yanone Kaffeesatz', 'Sans-Serif', serif, Arial;
  --font-intro: 'Libre Baskerville', 'Sans-Serif', serif, Arial;
  --font-default: 'Open Sans', 'Sans-Serif', serif, Arial;

  --content-maxwidth-wide: 1284px;
  --content-maxwidth-medium: 1066px;
  --content-maxwidth-default: 848px;
}
