.widget-textimageblocks {
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: #F7F7F7;
}
.widget-textimageblocks--gold {
  background-color: var(--color-gold-grey);
}
.widget-textimageblocks__block {
  display: flex;
}
.widget-textimageblocks__block__image, .widget-textimageblocks__block__content {
  flex: none;
  width: 50%;
}
.widget-textimageblocks__block__content {
  padding: clamp(15px, 4dvw, 40px) clamp(20px, 4.5dvw, 45px);
  font-size: clamp(14px, 1.5dvw, 15px);
  color: var(--color-grey-dark6a);
}
.widget-textimageblocks__block__suptitle {
  font-family: var(--font-intro);
  font-style: italic;
  margin-bottom: 10px;
  font-size: clamp(17px, 2dvw, 20px);
}
.widget-textimageblocks__block__image {
  position: relative;
}
.widget-textimageblocks__block__image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.widget-textimageblocks__block__image--right {
  order: 2;
}
.widget-textimageblocks__block__image > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.widget-textimageblocks__block:first-child .widget-textimageblocks__block__image--right > img {
  border-radius: 0 10px 0 0;
}
.widget-textimageblocks__block:first-child .widget-textimageblocks__block__image--left > img {
  border-radius: 10px 0 0 0;
}
.widget-textimageblocks__block:last-child .widget-textimageblocks__block__image--right > img {
  border-radius: 0 0 10px 0;
}
.widget-textimageblocks__block:last-child .widget-textimageblocks__block__image--left > img {
  border-radius: 0 0 0 10px;
}
@media (max-width: 800px) {
  .widget-textimageblocks__block {
    display: block;
  }
  .widget-textimageblocks__block__image, .widget-textimageblocks__block__content {
    width: 100%;
  }
  .widget-textimageblocks__block__image--right {
    order: initial;
  }
  .widget-textimageblocks__block:first-child .widget-textimageblocks__block__image > img {
    border-radius: 10px 10px 0 0;
  }
  .widget-textimageblocks__block:last-child .widget-textimageblocks__block__image > img {
    border-radius: 0 !important;
  }
}