.widget-products__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.widget-products__product {
  flex: none;
  width: calc(50% - 15px);
  margin-bottom: clamp(20px, 3vw, 30px);
}
@media (max-width: 950px) {
  .widget-products__product {
    width: 100%;
  }
}
.widget-products__product__link {
  height: 100%;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-grey-lighter);
  border: 1px solid var(--color-grey-light);
  transition: box-shadow 300ms;
  padding: clamp(20px, 3vw, 30px);
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [image-start] 130px [image-end] 30px [text-start] calc(100% - 160px) [text-end];
}
@media (max-width: 1000px) {
  .widget-products__product__link {
    grid-template-columns: [image-start] 13vw [image-end] 3vw [text-start] calc(100% - 16vw) [text-end];
  }
}
@media (max-width: 580px) {
  .widget-products__product__link {
    grid-template-columns: [image-start] 75px [image-end] 20px [text-start] calc(100% - 95px) [text-end];
  }
}
.widget-products__product__image {
  grid-area: 1/image-start/3/image-end;
}
.widget-products__product__image img {
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border: 1px solid var(--color-grey-light);
}
.widget-products__product__text {
  grid-area: 1/text-start/2/text-end;
}
.widget-products__product__title {
  color: var(--color-red);
  margin-bottom: 10px;
}
.widget-products__product__description {
  font-size: 14px;
  line-height: 180%;
}
.widget-products__product__footer {
  grid-area: 2/text-start/3/text-end;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.widget-products__product__price {
  font-size: clamp(20px, 3.3vw, 33px);
  font-family: var(--font-heading);
  margin-right: clamp(15px, 2vw, 30px);
}
@media (max-width: 500px) {
  .widget-products__product__image {
    grid-area: 1/image-start/2/image-end;
  }
  .widget-products__product__footer {
    grid-area: 2/image-start/3/text-end;
  }
  .widget-products__product__price {
    min-width: 80px;
  }
}