main > .wh-video {
  margin-bottom: 50px;
}

.wh-video {
  aspect-ratio: 16/9;
}

.wh-requireconsent__overlay {
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: clamp(15px, 2.4dvw, 24px);
  font-size: 85%;
}

.wh-requireconsent__overlay + .wh-video__innerframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
.wh-requireconsent__overlay + .wh-video__innerframe .wh-video__playbutton {
  display: none;
}

.wh-video__innerframe, .wh-requireconsent__overlay {
  border-radius: 10px;
  overflow: hidden;
}

.wh-video + p.caption {
  margin-top: -40px;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-red);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 0 none;
  border-radius: 50%;
  transition: transform 0.3s;
}
html.theme-gold .wh-video__playbutton {
  background-color: var(--color-gold);
}
.wh-video__playbutton::after {
  content: "";
  flex: none;
  margin-left: 7px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #fff;
}

.wh-video__innerframe__preview:hover .wh-video__playbutton {
  transform: translate(-50%, -50%) scale(1.1);
}