.widget-socialmedia {
  margin-bottom: 50px;
}
.widget-socialmedia ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 28px;
  align-items: center;
}
.widget-socialmedia ul li + li {
  margin-left: 15px;
}
.widget-socialmedia__icon {
  width: 28px;
}
.widget-socialmedia__icon > svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  fill: #000;
}
.widget-socialmedia a {
  color: #000;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s;
}
.widget-socialmedia a:hover {
  color: var(--color-red);
}
html.theme-gold .widget-socialmedia a:hover {
  color: var(--color-gold);
}