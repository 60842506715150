.widget-targetgroups {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
}
.widget-targetgroups__title {
  font-size: clamp(30px, 4.4dvw, 44px);
  font-weight: 500;
  margin-bottom: 5px;
}
.widget-targetgroups__text {
  font-size: clamp(18px, 2.6dvw, 26px);
  font-family: var(--font-intro);
  font-style: italic;
  margin-bottom: clamp(5px, 1.5dvw, 15px);
}
.widget-targetgroups__block {
  flex: none;
  width: 50%;
  padding: clamp(20px, 3.5dvw, 35px);
  border-radius: 10px 0 0 10px;
}
.widget-targetgroups__block + .widget-targetgroups__block {
  border-radius: 0 10px 10px 0;
}
.widget-targetgroups__block--red {
  background-color: #fff;
}
.widget-targetgroups__block--red h2, .widget-targetgroups__block--red li {
  color: var(--color-red);
}
.widget-targetgroups__block--red li {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--color-red);
}
.widget-targetgroups__block--red li:first-child {
  color: #fff;
  background-color: var(--color-red);
}
.widget-targetgroups__block--gold {
  background-color: var(--color-gold-grey);
}
.widget-targetgroups__block--gold h2, .widget-targetgroups__block--gold li {
  color: var(--color-gold);
}
.widget-targetgroups__block--gold li {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--color-gold);
}
.widget-targetgroups__block--gold li:first-child {
  color: #fff;
  background-color: var(--color-gold);
  background-image: linear-gradient(to bottom, #835600 0%, #946711 100%);
}
.widget-targetgroups__tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -15px;
  font: 24px var(--font-heading);
}
.widget-targetgroups__tags a {
  text-decoration: none;
  padding: 5px clamp(15px, 2.5dvw, 25dvw) 1px;
  display: inline-flex;
  align-items: flex-start;
  color: inherit;
  align-items: center;
  min-height: clamp(42px, 5.2dvw, 52px);
}
.widget-targetgroups__tags a > i {
  position: relative;
  top: -2px;
  font-size: 20px;
  margin-left: 10px;
}
.widget-targetgroups__tags li {
  display: inline-block;
  border-radius: 4px;
  margin-top: clamp(10px, 1.5dvw, 15px);
  margin-left: clamp(10px, 1.5dvw, 15px);
}
@media (max-width: 750px) {
  .widget-targetgroups {
    flex-flow: column;
  }
  .widget-targetgroups__block {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .widget-targetgroups__block + .widget-targetgroups__block {
    border-radius: 0 0 10px 10px;
  }
}