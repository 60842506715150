.photosoverlay {
  background-color: #333;
  color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vwh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  overflow: hidden;
}
.photosoverlay__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: none;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.photosoverlay__list li + li {
  margin-left: -100%;
}
.photosoverlay__item {
  flex: none;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
.photosoverlay__item--selected {
  opacity: 1;
  z-index: 1;
}
.photosoverlay__item .title {
  flex: none;
  margin-bottom: 10px;
  font-size: 150%;
}
.photosoverlay__item__image-wrapper {
  max-width: calc(100% - 30px);
  max-height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}
.photosoverlay__item__image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

/* overlay (old) */
.photoalbum-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-grey-dark25);
  z-index: 1000;
  color: #fff;
}
.photoalbum-overlay h2 {
  color: #fff;
}
.photoalbum-overlay__title {
  margin: 25px 0 30px;
  padding: 0 80px;
  color: #fff;
  text-align: center;
}
.photoalbum-overlay .counter {
  position: absolute;
  top: 26px;
  left: 30px;
}
.photoalbum-overlay .close {
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 1;
}
.photoalbum-overlay .next, .photoalbum-overlay .previous {
  position: absolute;
  top: 80px; /* keep space for counter and close btn */
  bottom: 80px;
  width: 60px;
  cursor: pointer;
  font-size: 54px;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}
.photoalbum-overlay .next.disabled, .photoalbum-overlay .previous.disabled {
  cursor: default;
  opacity: 0.3;
}
.photoalbum-overlay .next {
  right: 0;
  justify-content: flex-start;
}
.photoalbum-overlay .next:not(.disabled) > *:hover {
  transform: translateX(5px);
}
.photoalbum-overlay .previous {
  left: 0;
  justify-content: flex-end;
}
.photoalbum-overlay .previous:not(.disabled) > *:hover {
  transform: translateX(-5px);
}
.photoalbum-overlay .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photoalbum-overlay .image {
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.5, 0, 0, 1), width 200ms, height 200ms;
}
.photoalbum-overlay .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s;
}
.photoalbum-overlay .image__sizer > span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 50px;
  opacity: 0.5;
  display: none;
}
.photoalbum-overlay .image--loading .image__sizer > span {
  display: block;
}
.photoalbum-overlay .image > .title {
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 15px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 600px) {
  .photoalbum-overlay .next, .photoalbum-overlay .previous {
    justify-content: center;
  }
}