@charset "UTF-8";
.icon-twitter, .fab.fa-twitter {
  display: inline-block;
}
.icon-twitter::before, .fab.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 100%;
  font-weight: 700;
  font-family: Arial-black;
}

.bg-red {
  background-color: var(--color-red);
}

.bg-gold {
  background-color: var(--color-gold);
}

.bg-yellow {
  background-color: var(--color-yellow);
}

.bg-black {
  background-color: var(--color-black);
}

.fg-red {
  color: var(--color-red);
}

.fg-gold {
  color: var(--color-gold);
}

.fg-yellow {
  color: var(--color-yellow);
}

.fg-black {
  color: var(--color-black);
}

/*
Grid 85px / 24px
Outer padding: 20px, max site width: 1304px
Wide (12col): 1284px
Medium (10col): 1066px
Content (8col): 848px
*/
.contentgrid {
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [fullwidth-start] calc(50% - 652px) [outerpadding-left] 20fr [wide-start] 109fr [medium-start] 109fr [default-start] minmax(0, 848fr) [default-end] 109fr [medium-end] 109fr [wide-end] 20fr [outerpadding-right] calc(50% - 652px) [fullwidth-end];
}
@media (max-width: 780px) {
  .contentgrid {
    grid-template-columns: [fullwidth-start] 0 [outerpadding-left] 20px [wide-start] 109fr [medium-start] 0 [default-start] minmax(0, 1066fr) [default-end] 0 [medium-end] 109fr [wide-end] 20px [outerpadding-right] 0 [fullwidth-end];
  }
}
@media (max-width: 620px) {
  .contentgrid {
    grid-template-columns: [fullwidth-start] 0 [outerpadding-left] 20px [wide-start] 0 [medium-start] 0 [default-start] minmax(0, 1fr) [default-end] 0 [medium-end] 0 [wide-end] 20px [outerpadding-right] 0 [fullwidth-end];
  }
}
.contentgrid > * {
  grid-area: auto/default-start/auto/default-end;
}
.contentgrid > *.contentgrid__wide, .contentgrid > *.webshop-standardpage {
  grid-area: auto/wide-start/auto/wide-end;
}
.contentgrid > *.contentgrid__medium {
  grid-area: auto/medium-start/auto/medium-end;
}
.contentgrid > *.fullwidth, .contentgrid > *.contentgrid__fullwidth {
  grid-area: auto/fullwidth-start/auto/fullwidth-end;
}

.styledscrollbar-x,
.styledscrollbar-y {
  overflow: hidden;
  scroll-behavior: smooth;
  /* firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color-red) var(--color-grey-lighter);
  /* webkit */
}
.styledscrollbar-x::-webkit-scrollbar,
.styledscrollbar-y::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track-piece,
.styledscrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-button, .styledscrollbar-x::-webkit-scrollbar-corner, .styledscrollbar-x::-webkit-resizer,
.styledscrollbar-y::-webkit-scrollbar-button,
.styledscrollbar-y::-webkit-scrollbar-corner,
.styledscrollbar-y::-webkit-resizer {
  display: none;
}
.styledscrollbar-x::-webkit-scrollbar-thumb,
.styledscrollbar-y::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 7px 7px var(--color-red);
}
.styledscrollbar-x::-webkit-scrollbar-track,
.styledscrollbar-y::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px 7px var(--color-grey-lighter);
}

.styledscrollbar-x {
  overflow-x: auto;
}

.styledscrollbar-y {
  overflow-y: auto;
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* loading spinner */
.spinanimation {
  -webkit-animation: spinner-anim 2s infinite linear;
  animation: spinner-anim 2s infinite linear;
}

@-webkit-keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fade-anim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes beat {
  0%, 90%, 95%, 100% {
    transform: scale(1, 1);
  }
  92.5%, 97.5% {
    transform: scale(0.92, 0.95);
  }
}
@keyframes beat2 {
  0%, 50%, 100% {
    transform: scale(1, 1);
  }
  30%, 80% {
    transform: scale(0.92, 0.95);
  }
}