.wh-form--uploading .canvasupload {
  cursor: default;
}
.wh-form--uploading .canvasupload .canvasupload__busy {
  display: flex;
}
.wh-form--uploading .canvasupload .canvasupload__note {
  display: none;
}

.wh-form__fieldgroup.wh-form__fieldgroup--canvasupload {
  align-items: flex-start;
}

.canvasupload {
  display: flex;
  align-items: flex-start;
  font-size: clamp(13px, 1.4dvw, 14px);
  font-style: italic;
}
.canvasupload__imgpanel {
  position: relative;
  cursor: pointer;
  flex: none;
  width: clamp(100px, 19.2dvw, 192px);
  aspect-ratio: 1/1;
}
.canvasupload__tools {
  flex: 1;
  padding: 5px 0 5px clamp(15px, 2dvw, 20px);
}
.canvasupload__note {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  line-height: 135%;
  justify-content: center;
  align-items: center;
}
.canvasupload__note .icon {
  font-size: clamp(24px, 3.6dvw, 36px);
}
.canvasupload__note .text {
  padding: 5px 10px;
  text-align: center;
  font-style: italic;
}
.canvasupload.canedit .canvasupload__note {
  display: none;
}
.canvasupload__busy {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 60px;
}
.canvasupload.canvasupload--disabled {
  cursor: default;
}
.canvasupload.canvasupload--disabled .canvasupload__note {
  display: none;
}
.canvasupload__imgcontainer {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-grey-dark25);
  border-radius: 3px;
}
.canvasupload__imgcontainer::after {
  pointer-events: none;
  display: none;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 71%, rgba(255, 255, 255, 0.5) 0%);
  background-size: 100% 100%;
  background-position: 50% 50%;
  z-index: 1;
}
.canvasupload.canedit .canvasupload__imgcontainer::after {
  display: block;
}
.canvasupload__img {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0% 0%;
}
.canvasupload.canedit .canvasupload__img {
  cursor: move;
}
.canvasupload .canvasupload__description {
  display: flex;
  align-items: center;
}
.canvasupload.canedit .canvasupload__description {
  display: none;
}
.canvasupload__dragnote {
  flex-flow: row;
  align-items: baseline;
  margin-bottom: 30px;
}
.canvasupload__dragnote .icon {
  font-size: 18px;
  flex: none;
  width: 30px;
  color: var(--color-red);
}
.canvasupload__scale {
  display: block;
  width: 100%;
  transition: opacity 0.2s;
  z-index: -1;
  visibility: hidden;
}
.canvasupload__scale .text {
  margin-top: 10px;
}
.canvasupload.canedit .canvasupload__dragnote {
  display: flex;
}
.canvasupload.canedit .canvasupload__scale {
  visibility: visible;
  z-index: 1;
}
.canvasupload__input {
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
}
.canvasupload__value {
  height: 100%;
  width: 100%;
}
.canvasupload__clear {
  position: absolute;
  z-index: 2;
  bottom: 1px;
  right: 1px;
  width: 20px;
  height: 20px;
  display: block;
  color: #000;
  background-color: #FFF;
  border: 1px solid var(--color-grey-dark25);
  border-radius: 3px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
}