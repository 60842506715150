/* radio / checkbox styling */
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox], .webshop-categoryfilter__checkbox {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}

.webshop-categoryfilter__values {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.webshop-categoryfilter--multiple .webshop-categoryfilter__value {
  padding-bottom: 5px;
}
.webshop-categoryfilter__value {
  position: relative;
}
.webshop-categoryfilter__checkbox {
  cursor: pointer;
}
.webshop-categoryfilter__checkbox + label {
  padding-left: 30px;
}
.webshop-categoryfilter__checkbox + label::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  line-height: 14px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-grey-dark25);
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s;
}
.webshop-categoryfilter__checkbox:checked + label::after {
  content: "\f00c";
  background-color: var(--color-grey-dark25);
}
.webshop-categoryfilter__checkbox:checked + label {
  border-color: var(--color-grey-dark25);
}

.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 4px;
  flex: none;
  height: 24px;
  width: 24px;
  border: 1px solid var(--color-grey-dark25);
  background-color: #fff;
  border-radius: 3px;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  display: block;
  color: #fff;
  padding: 0;
  width: 100%;
  height: 100%;
  font-weight: 400;
  line-height: 18px;
  transition: all 0.2s;
}
.wh-styledinput input[type=radio]:focus + label, .wh-styledinput input[type=radio]:hover + label,
.wh-styledinput input[type=checkbox]:focus + label,
.wh-styledinput input[type=checkbox]:hover + label {
  border-color: var(--color-grey-dark25);
  border-width: 2px;
}
.wh-styledinput input[type=radio]:checked + label,
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: var(--color-grey-dark25);
  background-color: var(--color-grey-dark25);
  border-width: 2px;
}
.wh-styledinput input[type=radio]:focus + label, .wh-styledinput input[type=radio]:hover + label,
.wh-styledinput input[type=checkbox]:focus + label,
.wh-styledinput input[type=checkbox]:hover + label {
  border-color: var(--color-grey-dark25);
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: var(--color-grey-light);
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: var(--color-grey-dark25);
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  margin-bottom: 15px;
}

.wh-form__optiondata > * {
  padding-left: 15px;
}

.wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}

.wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 5px;
}

.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  border: 3px solid #fff;
  border-radius: 50%;
}
.wh-styledinput input[type=radio]:checked + label::after {
  background-color: var(--color-grey-dark25);
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: var(--color-grey-lighter);
  border-color: var(--color-grey-lighter);
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: var(--color-grey-light);
}

.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
}
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: var(--color-grey-dark25);
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  background-color: var(--color-grey-lighter);
  color: var(--color-grey-light);
}

.wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: var(--color-red);
}