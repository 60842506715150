.widget-downloads {
  margin-bottom: clamp(30px, 5dvw, 50px);
}
.widget-downloads__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-red);
}
html.theme-gold .widget-downloads__list {
  border-color: var(--color-gold);
}
.widget-downloads__item {
  border-bottom: 1px solid var(--color-red);
}
html.theme-gold .widget-downloads__item {
  border-color: var(--color-gold);
}
.widget-downloads__item__link {
  text-decoration: none;
  color: var(--color-grey-dark25);
  padding: 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: clamp(15px, 1.8dvw, 18px);
  text-underline-position: under;
}
.widget-downloads__item__link:hover .widget-downloads__item__title b {
  text-decoration: underline;
}
.widget-downloads__item__type {
  color: var(--color-grey-dark6a);
  flex: none;
}
.widget-downloads__item__type {
  font-size: 26px;
  margin-right: 20px;
}
.widget-downloads__item__description {
  margin-top: 5px;
  color: var(--color-black);
}
.widget-downloads__item__text {
  flex: 1 1 auto;
}
.widget-downloads__item__title b {
  color: var(--color-red);
  font-size: clamp(17px, 2dvw, 20px);
}
html.theme-gold .widget-downloads__item__title b {
  color: var(--color-gold);
}