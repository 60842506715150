.widget-quicklinks {
  margin-bottom: 50px;
}
.widget-quicklinks__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-quicklinks__item {
  margin-bottom: 10px;
}
.widget-quicklinks__item__link {
  color: var(--color-red);
  text-decoration: none;
  text-underline-position: under;
}
html.theme-gold .widget-quicklinks__item__link:not(.fg-red) {
  color: var(--color-gold);
}
.widget-quicklinks__item__title {
  font: 24px var(--font-heading);
  display: inline-flex;
  align-items: flex-start;
}
.widget-quicklinks__item__title::before {
  content: "\f0a9";
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 18px;
  margin-right: 10px;
}
.widget-quicklinks__item__link:hover > .widget-quicklinks__item__title > span {
  text-decoration: underline;
}
.widget-quicklinks__item__description {
  color: var(--color-grey-dark6a);
  font-size: clamp(14px, 1.5dvw, 15px);
}