.productpage__columns {
  display: flex;
}
.productpage__columns__col {
  flex: none;
  width: 50%;
}
@media (max-width: 600px) {
  .productpage__columns {
    flex-flow: column;
  }
  .productpage__columns__col {
    width: 100%;
  }
}

.productpage__productheader {
  border-radius: 10px;
}
.productpage__productheader__details {
  padding: clamp(20px, 3vw, 30px);
}
.productpage__productheader h1 {
  text-align: left;
  margin-bottom: 20px;
}
.productpage__productheader p {
  font-size: 15px;
}
.productpage__productheader__images {
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  aspect-ratio: 1/1;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media (max-width: 600px) {
  .productpage__productheader__images {
    border-radius: 10px 10px 0 0;
    aspect-ratio: initial;
  }
}
.productpage__productheader__images__holder {
  margin-bottom: 20px;
}
.productpage__productheader__images__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding-bottom: 20px;
}
.productpage__productheader__images__list > li {
  flex: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productpage__productheader__images img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  pointer-events: none;
}
@media (max-width: 600px) {
  .productpage__productheader__images img {
    max-height: 50dvw;
    object-fit: contain;
  }
}
.productpage__productheader__details {
  background-color: var(--color-grey-lighter);
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-flow: column;
}
@media (max-width: 600px) {
  .productpage__productheader__details {
    border-radius: 0 0 10px 10px;
  }
}
.productpage__productheader__details__content {
  flex: 1;
}
.productpage__productheader__details__footer {
  flex: none;
}
.productpage__productheader__details__price {
  margin-top: clamp(20px, 3vw, 30px);
  font-family: var(--font-heading);
  font-size: clamp(33px, 4.8vw, 48px);
}
.productpage__productheader__details__fieldgroup {
  margin-top: 20px;
  display: flex;
}
.productpage__productheader__details__fieldgroup input {
  max-width: 80px;
  height: 42px;
  margin-right: 15px;
  font-size: 18px;
  background-color: #fff;
}
.productpage__productheader__details__fieldgroup button.button {
  height: 48px;
}
.productpage__productheader__details__note {
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  font-family: var(--font-heading);
}