@import "/.publisher/sd/hartvoorjestad/site/fonts/opensans/opensans.css";
@import "/.publisher/sd/hartvoorjestad/site/fonts/librebaskerville/librebaskerville.css";
@import "/.publisher/sd/hartvoorjestad/site/fonts/yanonekaffeesatz/yanonekaffeesatz.css";
@import "/.publisher/sd/hartvoorjestad/site/fonts/fontawesome5/css/all.min.css";

html
{
  font: 18px 'Open Sans', sans-serif, serif, Arial;
  color: #3a3a3a;
  font-size: clamp(15px, 1.8vw, 18px);
}

h1, h2, h3, h4
{
  font-family: 'Yanone Kaffeesatz', 'Sans-Serif', Arial, Sans-Serif;
  font-weight: normal;
}
h2 + .widget, h3 + .widget
{
  margin-top: clamp(10px, 1.5dvw, 15px);
}

h1
{
  line-height: 100%;
  font-size: clamp(32px, 6.4dvw, 64px);
  text-align: center;
}
html.webshop--ishomepage h1, h1.large
{
  font-size: clamp(32px, 9.4dvw, 94px);
}
h1.small
{
  font-size: clamp(32px, 4.8dvw, 48px);
}
h2
{
  font-size: clamp(28px, 3.2dvw, 32px);
  margin-bottom: clamp(5px, 1dvw, 10px);
}
html.theme-gold h2:not(.fg-red)
{
  color: var(--color-gold);
}

h3
{
  font-size: clamp(24px, 2.8dvw, 28px);
}
h4
{
  font-size: clamp(15px, 1.6dvw, 16px);
}

p a
{
  color: inherit;
  text-align: center;
}

p
{
  margin-bottom: 25px;
  line-height: 165%;
}

p.caption
{
  font-size: 90%;
  color: var(--color-grey-dark76);
  font-style: italic;
}

p.intro, p.introleft, p.pagedate
{
  font-family: 'Libre Baskerville', 'Sans-Serif', Arial, Sans-Serif;
  font-size: clamp(17px, 2.6dvw, 26px);
  line-height: 150%;
  font-style: italic;
  max-width: 730px;
  margin: 0 auto clamp(20px, 3.5dvw, 35px) auto;
  text-align: center;
}
p.introleft
{
  text-align: left;
  max-width: 100%;
}

p.intro b, p.introleft b
{
  font-weight: inherit;
  color: var(--color-red);
}

html.theme-gold p.intro b, html.theme-gold p.introleft b
{
  color: var(--color-gold);
}

p.intro u, p.introleft u
{
  text-decoration: none;
  color: var(--color-green);
}

p a:not(.button), .unordered a:not(.button), .ordered a:not(.button)
{
  color: inherit;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  color: var(--color-red);
  transition: color 300ms;

  html.theme-gold &:not(.fg-red)
  {
    color: var(--color-gold);
  }
}


sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 30px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-bottom: 0;
}

  p + ul.unordered
, p + ol.ordered
{
  margin-top: -20px;
}

  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  padding: 0;
  line-height: 150%;
  list-style-type: none;
}

  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 0 25px;
  min-height: 15px;
}

  ul.unordered li::before
, ol.ordered li::before
{
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
}
  ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 25px;
}

  ul.unordered > li::before
, ul.unordered ul > li::before
, ol.ordered ul > li::before
{
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 10px;
}

  ol.ordered
, ol.ordered ol
, ul.unordered ol
{
  counter-reset: numlist;
}
  ol.ordered > li::before
, ol.ordered ol > li::before
, ul.unordered ol > li::before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 30px;
  max-width: 100%;
}
.wh-rtd__table
{
  border: 0 none
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  background-color: var(--color-red);

  html.theme-gold &:not(.fg-red)
  {
    background-color: var(--color-gold);
  }
}
