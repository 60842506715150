.wh-searchresults__feedback {
  margin-top: 15px;
}
.wh-searchresults__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.wh-searchresults .consilio--highlight {
  display: inline-block;
  position: relative;
}
.wh-searchresults .consilio--highlight .highlight.bgtheme {
  bottom: calc((100% - 1em) / 2 - 2px);
  display: block;
  font-size: inherit;
  background-color: var(--color-red);
  height: 9px;
  left: -3px;
  opacity: 0.15;
  position: absolute;
  right: -3px;
  z-index: -1;
}
.wh-searchresults__listitem {
  margin-bottom: clamp(20px, 3vw, 30px);
}
.wh-searchresults__listitem[hidden] {
  display: none;
}
.wh-searchresults__listitem__link {
  display: block;
  text-decoration: none;
  color: inherit;
  width: 100%;
}
.wh-searchresults__listitem__title {
  font-size: 24px;
  font-family: var(--font-heading);
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-searchresults__listitem__summary {
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-grey-dark);
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-searchresults__listitem__url {
  font-size: 14px;
  color: var(--color-red);
  white-space: nowrap;
}
.wh-searchresults__listitem__url__name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}