.canvasupload .wh-slider-holder {
  display: block;
  white-space: nowrap;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
  padding: 0 12px;
  margin: 0;
}
.canvasupload .wh-slider-holder:before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  height: 4px;
  left: 2px;
  right: 2px;
  border-radius: 4px;
  background-color: #979797;
}
.canvasupload .wh-slider-holder > .wh-slider {
  position: relative;
  top: 10px;
  margin-right: 0;
  height: 4px;
}
.canvasupload .wh-slider-holder > .wh-slider > .wh-slider-knob {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  margin-top: -10px;
  margin-left: -12px;
  height: 24px;
  width: 24px;
  background-color: var(--color-red);
  border-radius: 100%;
  box-shadow: 0.5px 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #EFEFEF;
}