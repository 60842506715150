html.mobilenav.mobilenav--show {
  overflow: hidden;
}

.header {
  z-index: 10;
  color: var(--color-grey-dark25);
  font-family: "Yanone Kaffeesatz", "Sans-Serif", Arial, Sans-Serif;
  margin-top: 56px;
  height: 76px;
}
@media (max-width: 900px) {
  .header {
    height: 69px;
  }
}
@media (max-width: 800px) {
  .header {
    height: 62px;
  }
}
@media (max-width: 700px) {
  .header {
    height: 54px;
  }
}
.header::before {
  content: "";
  display: block;
  height: 100%;
}
.header__wrapper {
  position: fixed;
  left: 0;
  height: inherit;
  right: 0;
  top: clamp(20px, 5.6dvw, 56px);
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 36px 0 rgba(0, 0, 0, 0.16);
  padding: 0 0 0 clamp(15px, 2.4dvw, 24px);
}
.header__logo {
  text-decoration: none;
  display: inline-block;
  flex: none;
  margin: 0 10px 0 0;
}
.header__logo img {
  width: clamp(155px, 21.2dvw, 212px);
  height: auto;
  vertical-align: bottom;
}
.header__nav {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  position: relative;
}
.header__nav__desktopsearch {
  display: none;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #FFF;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  transition: width 300ms, opacity 300ms;
}
.header__nav__desktopsearch--beforeshow {
  display: flex;
}
.header__nav__desktopsearch--show {
  display: flex;
  opacity: 1;
  width: calc(100% - 55px);
}
.header__nav__desktopsearch--aftershow {
  overflow: visible;
}
html.mobilenav .header__nav__desktopsearch {
  display: none !important;
}
.header__nav__desktopsearch__searchform {
  display: block;
  flex: 1;
  border: 1px solid var(--color-divider);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 44px;
  color: var(--color-grey-dark);
}
.header__nav__desktopsearch__searchform input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 100%;
  flex: 1 1 auto;
  font: inherit;
  border: 0 none;
  padding: 2px 15px 0;
  border-radius: 0;
  min-width: 100px;
  background-color: transparent;
  display: block;
}
.header__nav__desktopsearch__searchform button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  background-color: var(--color-red);
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  padding: 5px 10px;
  border-radius: 8px;
  margin-right: 4px;
}
.header__nav__desktopsearch--show ~ .header__nav__scrollwrapper .header__nav__button--search {
  opacity: 0.5;
}
.header__nav__desktopsearch--show ~ .header__nav__scrollwrapper .header__nav__list {
  pointer-events: none;
}
.header__nav__mobileheader {
  flex: none;
  color: var(--color-grey-dark76);
  padding: 12px 20px;
  font: 15px "Open Sans", sans-serif, serif, Arial;
}
html.mobilenav .header__nav__mobileheader {
  display: block;
}
.header__nav__mobileheader__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__nav__mobileheader__logo {
  width: 50px;
  height: 46px;
  display: block;
  text-decoration: none;
  color: inherit;
}
.header__nav__mobileheader__logo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.header__nav__mobileheader__close {
  flex: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.header__nav__mobileheader__close i {
  font-size: 24px;
  margin-left: 10px;
}
.header__nav__mobileheader__searchform {
  display: block;
  margin-top: 12px;
  border: 1px solid var(--color-divider);
  border-radius: 10px;
  display: flex;
  height: 44px;
  color: var(--color-grey-dark);
}
.header__nav__mobileheader__searchform input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 100%;
  flex: 1 1 auto;
  font: inherit;
  border: 0 none;
  padding: 0 15px;
  border-radius: 0;
  min-width: 100px;
  background-color: transparent;
  display: block;
}
.header__nav__mobileheader__searchform button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  font-size: 24px;
  padding: 5px 10px;
}
html.mobilenav .header__nav {
  transition: height 300ms, opacity 300ms;
  position: absolute;
  z-index: 11;
  background-color: #fff;
  left: 0;
  top: clamp(-56px, -5.6dvw, -20px);
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  display: none;
}
html.mobilenav.mobilenav--show .header__nav {
  display: flex;
  flex-flow: column;
}
.header__nav__scrollwrapper {
  display: flex;
}
html.mobilenav .header__nav__scrollwrapper {
  flex: 1 1 auto;
  display: block;
}
.header__nav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  font-size: clamp(20px, 2.4vw, 24px);
  border-right: 1px solid #ECECEC;
}
html.mobilenav .header__nav__list {
  display: block;
  height: auto;
  margin-top: 30px;
  font-size: 24px;
  border: 0 none;
}
html.mobilenav .header__nav__list > li + li {
  margin-top: 20px;
}
html.mobilenav .header__nav__list > li:not(.header__nav__list__item--haschildren) {
  padding-left: 70px;
}
.header__nav__list__item {
  border-left: 1px solid #ECECEC;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 100px;
}
html.mobilenav .header__nav__list__item {
  padding-left: 20px;
  flex-wrap: wrap;
}
.header__nav__buttons {
  display: flex;
  padding-left: 10px;
}
html.mobilenav .header__nav__buttons {
  margin-top: 30px;
  display: block;
  padding-left: 20px;
}
html.mobilenav .header__nav__buttons .header__nav__button__icon {
  width: 50px;
  margin-right: 0;
  text-align: center;
}
html.mobilenav .header__nav__buttons .header__nav__button__icon i {
  margin: 0;
}
html.mobilenav .header__nav__buttons .header__nav__button--search {
  display: none;
}
.header__nav__button, .header__nav__toggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}
.header__nav__toggle {
  color: var(--color-red);
  flex: none;
  width: 50px;
  height: 100%;
  padding: 4px 15px 0;
  margin-left: -10px;
}
.header__nav__toggle[aria-expanded=true] i {
  transform: rotate(180deg);
}
html.mobilenav .header__nav__toggle {
  order: -1;
  margin: 0;
  font-size: 18px;
  padding: 0 15px;
  margin-top: -5px;
}
html.mobilenav .header__nav__toggle i {
  font-weight: 400;
  transform: rotate(-90deg);
}
html.mobilenav .header__nav__toggle[aria-expanded=true] i {
  transform: rotate(0deg);
}
.header__nav__button, .header__nav__link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 4px 15px 0;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  transition: text-shadow 300ms;
}
html.mobilenav .header__nav__button, html.mobilenav .header__nav__link {
  padding: 0;
}
.header__nav__button i, .header__nav__link i {
  color: var(--color-grey-dark25);
  display: inline-block;
  margin-right: 10px;
  font-size: clamp(22px, 2.6vw, 26px);
  flex: none;
  margin-bottom: 6px;
}
.header__nav__button:hover, .header__nav__link:hover {
  text-shadow: 0 0 1px var(--color-grey-dark25);
}
.header__nav__button {
  padding: 4px 5px 0;
  margin-bottom: 12px;
}
.header__nav__button__mobilelabel {
  font: 15px "Open Sans", sans-serif, serif, Arial;
}
.header__nav__button--languagetoggle .header__nav__button__label {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  left: -10px;
  top: -17px;
  font-weight: 600;
  font-size: 15px;
}
html.mobilenav .header__nav__button--languagetoggle .header__nav__button__label {
  display: none;
}
html.mobilenav .header__nav__button .header__nav__button__mobilelabel {
  display: block;
}
.header__nav__subitems {
  flex: none;
  display: block;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 300ms;
  width: 220px;
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  z-index: 20;
  font: 15px "Open Sans", sans-serif, serif, Arial;
}
html:not(.mobilenav) .header__nav__subitems {
  border: 1px solid #ECECEC;
  border-radius: 10px;
  box-shadow: 6px 10px 19px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
html.mobilenav .header__nav__subitems {
  width: 100%;
  top: 0;
  position: initial;
  padding: 0 0 0 50px;
}
.header__nav__subitems__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 20px;
}
html.mobilenav .header__nav__subitems__list {
  margin-top: 15px;
  padding: 0;
}
.header__nav__subitems__list__item + .header__nav__subitems__list__item {
  margin-top: 10px;
}
html.mobilenav .header__nav__subitems__list__item + .header__nav__subitems__list__item {
  margin-top: 10px;
}
.header__nav__subitems__list__item a {
  display: flex;
  color: inherit;
  text-decoration: none;
}
.header__nav__subitems__list__item a:hover {
  text-decoration: underline;
  text-underline-position: under;
}
html:not(.mobilenav) .header__nav__list__item:hover .header__nav__subitems, .header__nav__list__item.hasfocus .header__nav__subitems {
  max-height: 100vh;
  opacity: 1;
}
.header__cart {
  position: relative;
}
.header__cart__counter {
  position: absolute;
  display: inline-block;
  left: calc(100% - 18px);
  bottom: calc(100% - 5px);
  color: #fff;
  background-color: var(--color-red);
  line-height: 14px;
  font-weight: 700;
  font-size: 11px;
  height: 14px;
  min-width: 14px;
  text-align: center;
  padding: 0 3px;
  border-radius: 7px;
}
.header__cart__counter:empty {
  display: none;
}
.header__mobilenav {
  display: none;
  align-items: center;
}
html.mobilenav .header__mobilenav {
  display: flex;
}
.header__mobilenav i {
  color: var(--color-grey-dark25);
  display: inline-block;
  margin-right: 10px;
  font-size: clamp(22px, 2.6vw, 26px);
  flex: none;
}
.header__mobilenav .header__cart {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  font-size: clamp(20px, 2.4vw, 24px);
  padding: 4px 7px 0 10px;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  transition: text-shadow 300ms;
}
.header__mobilenav .header__cart:hover, .header__mobilenav .header__cart:focus {
  text-shadow: 0 0 1px var(--color-grey-dark25);
}
.header__mobilenav .header__cart__counter {
  left: calc(100% - 20px);
  bottom: calc(100% - 10px);
}
.header__mobilenav__toggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  padding: 10px;
  transition: text-shadow 300ms;
}
.header__mobilenav__toggle:hover, .header__mobilenav__toggle:focus {
  text-shadow: 0 0 1px var(--color-grey-dark25);
}