
/*
Buttons:
  .button ( default --color-red )
  .button--outline

  example
  <a href="#" class="button button--outline">
    <i class="button__icon fal fa-chevron-left"></i>
    <span class="button__label">Sign up</span>
  </a>
*/

.wh-form__buttongroup
{
  text-align: right;
  padding: 30px 0;
  margin-top: 30px;
  border-top: 1px solid var(--color-divider);
}
.wh-form__buttongroup--alignleft
{
  text-align: left;
}

.wh-form__buttongroup::after
{
  content: "";
  display: block;
  clear: both;
}
.wh-form__buttongroup .wh-form__button, .wh-form__buttongroup .button
{
  margin-bottom: 10px;
}

  .wh-form__buttongroup .wh-form__button + .wh-form__button
, .wh-form__buttongroup .button + .button
{
  margin-left: 15px;
}

.wh-form__page--visible[data-wh-form-pagerole='thankyou'] + .wh-form__buttongroup
{
  display: none;
}

.button, .wh-form__button
{
  outline: none;
  -webkit-appearance: none;
  position: relative;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  font: 24px/120% var(--font-heading);
  font-size: clamp(20px, 2.4vw, 24px);
  min-width: clamp(100px, 14dvw, 140px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: clamp(36px, 4.4vw, 44px);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0 15px;
  background-color: var(--color-red);
  color: #fff;
  text-align: left;
  transition: all 0.3s;
  opacity: 1;
  box-shadow: none;
  margin: 0;
  border: 0 none;
}

.button--wide
{
  width: 100%;
}

.button[disabled], .wh-form__button[disabled]
{
  opacity: 0.6 !important;
  pointer-events: none;
}

.button--small
{
  height: 40px;
  font-size: 18px;
  padding: 0 10px;
}

.button--large
{
  height: clamp(42px, 4.8dvw, 48px);
  font-size: clamp(18px, 2.8dvw, 28px);
  padding: 0 20px;
}

.button--gold
{
  background-color: var(--color-gold);
  background-image: linear-gradient(to bottom, #835600 0%, #946711 100%);
}
.button--green
{
  background-color: var(--color-green);
}
.button--yellow
{
  background-color: var(--color-yellow);
}
.button--black
{
  background-color: var(--color-grey-dark);
}
.button--white
{
  background-color: #fff;
  color: var(--color-black);
}

.button--lightgrey
{
  background-color: #f1f1f1;
  color: var(--color-red);
}
.button--lightgrey:focus, .button--lightgrey:hover
{
  color: #f1f1f1;
  background-color: var(--color-red);
}

.button__icon
{
  display: inline-flex;
  flex: none;
  align-items: center;
  height: 100%;
  font-size: 18px;
}

.button__icon:first-child
{
  margin-right: 15px;
}

.buttonlabel, .button__label
{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 3px;
  flex: 1 1 auto;
}

.button__label:last-child
{
  text-align: center;
}

.button__icon + .button__label
{
  text-align: left;
}

  .button__label + .button__icon
, .buttonlabel + .button__icon
{
  margin-left: 15px;
}

.button--outline
{
  background-color: transparent;
  background-image: none;
  color: var(--color-red);
  border: 1px solid var(--color-red);
  transition: border-color 0.2s;
}

.button--outline.button--gold
{
  border-color: var(--color-gold);
  color: var(--color-gold);
}
.button--outline.button--green
{
  border-color: var(--color-green);
  color: var(--color-green);
}
.button--outline.button--yellow
{
  border-color: var(--color-yellow);
  color: var(--color-yellow);
}
.button--outline.button--black
{
  border-color: var(--color-grey-dark);
  color: var(--color-grey-dark);
}
.button--outline.button--white
{
  border-color: #fff;
  color: #fff;
}

.button--text
{
  min-width: 0;
  color: var(--color-red);
  justify-content: flex-start;
  background-color: transparent;
  background-image: none;
  align-items: baseline;
  min-height: 27px;
  border-radius: 0;
  white-space: normal;
  height: auto;
  padding: 0;
}
.button--text.button--gold
{
  color: var(--color-gold);
}
.button--text.button--green
{
  color: var(--color-green);
}
.button--text.button--yellow
{
  color: var(--color-yellow);
}
.button--text.button--black
{
  color: var(--color-grey-dark);
}

.button--text .button__icon:first-child
{
  margin-right: 10px;
  font-size: 14px;
}
.button--text .button__label
{
  text-decoration: underline;
  text-underline-position: under;
}

.button--outline.button--text
{
  border: 0 none;
}

.button--outline.button--text .button__icon
{
  background-color:transparent;
  border: 1px solid var(--color-black);
  color: var(--color-black);
}

  .wh-form__button--previous, .button--previous
, .wh-form__button--cancel, .button--cancel
, .button--floatleft
{
  float: left;
}

  .wh-form__button--previous .button__icon + .button__label, .button--previous .button__icon + .button__label
, .wh-form__button--cancel .button__icon + .button__label, .button--cancel .button__icon + .button__label
{
  text-align: right;
}

.wh-form__button--cancel, .button--cancel
{
  float: none;
  vertical-align: bottom;
  min-width: 80px;
}
