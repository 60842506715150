.newsfilters {
  display: flex;
  align-items: center;
  margin-bottom: clamp(10px, 2.5dvw, 25px);
}
.newsfilters__totalcount {
  flex: 1;
  font-family: var(--font-intro);
  font-size: clamp(18px, 2.2dvw, 22px);
  font-style: italic;
}
.newsfilters__totalcount > span {
  font-size: clamp(22px, 2.6dvw, 26px);
}
.newsfilters__filters {
  margin-left: 20px;
  flex: none;
}
.newsfilters__filters.wh-styledinput select {
  min-width: clamp(100px, 33.5dvw, 335px);
  color: var(--color-grey-dark25);
}
@media (max-width: 500px) {
  .newsfilters {
    flex-flow: column;
  }
  .newsfilters__totalcount {
    margin-top: 15px;
    order: 1;
    width: 100%;
  }
  .newsfilters__filters {
    order: -1;
    margin-left: 0;
    width: 100%;
  }
}

.widget-news {
  margin-bottom: 50px;
}
.widget-news .widget-news__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: clamp(20px, 2.5dvw, 25px) 12px;
}
.widget-news__listholder {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #fff;
}
@media (max-width: 620px) {
  .widget-news__listholder {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.widget-news--grid .widget-news__list {
  flex-wrap: wrap;
}
.widget-news--grid .widget-news__item {
  margin-bottom: 24px;
}
.widget-news__footer {
  text-align: center;
  font-family: var(--font-intro);
}
.widget-news__footer a {
  font-style: italic;
  text-underline-position: under;
  color: var(--color-gold);
}
.widget-news__item {
  flex: none;
  width: 33.333%;
  padding: 0 12px;
}
.widget-news__item[hidden] {
  display: none;
}
.widget-news__item--centercontent {
  text-align: center;
}
.widget-news__item__link {
  height: 100%;
  min-height: 470px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(102, 102, 102, 0.2);
  border: 1px solid #e3e3e3;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: column;
}
.widget-news__item__image {
  flex: none;
  font-family: var(--font-heading);
  position: relative;
  color: #fff;
  line-height: 120%;
  overflow: hidden;
  z-index: 0;
  border-radius: 10px 10px 0 0;
}
.widget-news__item__image::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.widget-news__item__image > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transform-origin: center;
  position: relative;
  z-index: -1;
  transition: transform 0.3s;
}
.widget-news__item__link:hover .widget-news__item__image > img {
  transform: scale(1.05);
}
.widget-news__item__link:hover .button, .widget-news__item__link:focus .button {
  background-color: var(--color-red);
  color: #fff;
}
.widget-news__item__tag {
  position: absolute;
  right: 0;
  top: 0;
  max-width: calc(100% - 20px);
  padding: 12px clamp(20px, 2.5dvw, 25px) 8px;
  border-radius: 0 10px 0 10px;
  font-size: clamp(20px, 2.4dvw, 24px);
  text-transform: uppercase;
}
.widget-news__item__date {
  font-family: var(--font-heading);
  padding: 20px 17px 0;
  font-size: 18px;
}
.widget-news__item__title {
  flex: none;
  padding: 20px 17px 2px;
  text-transform: uppercase;
}
.widget-news__item__date + .widget-news__item__title {
  padding-top: 7px;
}
.widget-news__item__description {
  flex: 1;
  padding: 0 17px;
  font-size: 15px;
  color: var(--color-grey-dark6a);
  margin-bottom: 0;
}
.widget-news__item__buttonholder {
  flex: none;
  padding: 20px 17px;
}
.widget-news__item__arrow {
  font-size: clamp(30px, 3.6dvw, 36px);
}
@media (max-width: 900px) {
  .widget-news__item {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .widget-news__item {
    width: 100%;
  }
}
.widget-news__itemsnav {
  margin-bottom: 15px;
}