.widget-koopofproef {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0 7px 29px rgba(0, 0, 0, 0.1);
  padding: clamp(30px, 4dvw, 40px);
  margin-bottom: 50px;
}
.widget-koopofproef__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-koopofproef__text {
  flex: 1;
}
.widget-koopofproef__thumbleft, .widget-koopofproef__assets, .widget-koopofproef__thumbright {
  flex: none;
}
.widget-koopofproef__txtimg {
  width: 100px;
  height: auto;
}
.widget-koopofproef__thumbleft {
  width: clamp(150px, 15dvw, 176px);
}
.widget-koopofproef__thumbleft__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-koopofproef__assets {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  padding: 0 20px;
}
.widget-koopofproef__txtimg {
  width: 100px;
  height: auto;
}
.widget-koopofproef__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: clamp(220px, 20dvw, 262px);
  z-index: 0;
}
.widget-koopofproef__listitem {
  position: relative;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  flex: none;
  width: 100%;
  transition: opacity 0.8s;
}
.widget-koopofproef__listitem--active {
  z-index: 1;
  opacity: 1;
  pointer-events: initial;
}
.widget-koopofproef__listitem + .widget-koopofproef__listitem {
  margin-left: -100%;
}
.widget-koopofproef__thumbright {
  width: 100%;
  display: block;
  text-decoration: none;
  border: 1px solid var(--color-divider);
  border-radius: 10px;
}
.widget-koopofproef__thumbright__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 10px;
}
@media (max-width: 1000px) {
  .widget-koopofproef__content {
    flex-wrap: wrap;
  }
  .widget-koopofproef__thumbleft {
    width: 176px;
  }
  .widget-koopofproef__text {
    flex: none;
    width: calc(100% - 176px);
  }
  .widget-koopofproef__list {
    width: 275px;
    max-width: 100%;
  }
}
@media (max-width: 550px) {
  .widget-koopofproef__content {
    flex-flow: column;
  }
  .widget-koopofproef__assets {
    padding: 0 0 20px;
    position: relative;
    z-index: 2;
  }
  .widget-koopofproef__text {
    width: 100%;
  }
  .widget-koopofproef__arrow {
    display: none;
  }
  .widget-koopofproef__hartjes {
    position: absolute;
    bottom: 0;
    left: 100%;
    transform: scale(-1, 1) translate(-15px, 17px);
  }
}