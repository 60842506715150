.registrationpage .lieveamsterdammer__item {
  margin-bottom: clamp(24px, 4dvw, 40px);
}

.wh-form.rejectpageform .wh-form__buttongroup {
  border: 0 none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.consentpage__editlink {
  margin-left: clamp(20px, 4dvw, 40px);
  color: var(--color-red);
  font-size: clamp(20px, 2.4vw, 24px);
  white-space: nowrap;
  font-family: var(--font-heading);
  text-underline-position: under;
}
@media (max-width: 500px) {
  .consentpage .wh-form__buttongroup {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .consentpage__editlink {
    margin-left: 0;
    margin-top: 10px;
  }
}

.lieveamsterdammer__item {
  margin-top: 24px;
  border-radius: clamp(50px, 6.25dvw, 90px) 8px 8px 8px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  box-shadow: 0 7px 29px 0 rgba(0, 0, 0, 0.15);
  padding: clamp(15px, 1.65dvw, 24px);
  display: flex;
  align-items: flex-start;
}
.lieveamsterdammer__item, .lieveamsterdammer__item h3, .lieveamsterdammer__item p {
  font-family: var(--font-intro);
  font-style: italic;
}
.lieveamsterdammer__item__image {
  flex: none;
  width: clamp(100px, 10dvw, 155px);
  margin-right: 24px;
  font-size: clamp(11px, 1dvw, 14px);
  position: relative;
}
.lieveamsterdammer__item__image > div {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
}
.lieveamsterdammer__item__image__photo {
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  background-color: var(--color-divider);
}
.lieveamsterdammer__item__image__hartjes {
  position: absolute;
  right: 0;
  transform: translateX(25%);
  bottom: 0;
  width: 40%;
  height: auto;
  pointer-events: none;
}
.lieveamsterdammer__item__suptitle {
  color: var(--color-red);
  font-size: clamp(14px, 1.1dvw, 16px);
}
.lieveamsterdammer__item__name {
  margin-top: 5px;
  font-size: clamp(17px, 1.8dvw, 26px);
}
.lieveamsterdammer__item__story {
  margin-top: 5px;
  font-size: clamp(13px, 1dvw, 14px);
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  white-space: pre-wrap;
  transition: max-height 200ms;
  padding-bottom: 24px;
}
.lieveamsterdammer__item__story.hasellipsis {
  padding-bottom: 0;
}
.lieveamsterdammer__item__story.hasellipsis--open {
  -webkit-line-clamp: initial;
  line-clamp: initial;
}
.lieveamsterdammer__item__readmore {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  color: var(--color-red);
  font-family: inherit;
  font-size: clamp(13px, 1dvw, 14px);
  font-style: italic;
  line-height: 20px;
}
.lieveamsterdammer__item__story.hasellipsis + .lieveamsterdammer__item__readmore {
  display: inline-block;
}
.lieveamsterdammer__item__story.hasellipsis--open + .lieveamsterdammer__item__readmore > .lieveamsterdammer__item__readmore__more, .lieveamsterdammer__item__story.hasellipsis + .lieveamsterdammer__item__readmore > .lieveamsterdammer__item__readmore__less {
  display: none;
}
.lieveamsterdammer__item__story.hasellipsis--open + .lieveamsterdammer__item__readmore .lieveamsterdammer__item__readmore__less {
  display: inline-block;
}

.lieveamsterdammer__sortorder {
  margin: 15px auto;
  border: 1px solid var(--color-red);
  border-radius: 8px;
  font-size: 90%;
  color: var(--color-grey-dark);
  font-style: italic;
  padding: 12px 20px;
}
.lieveamsterdammer__sortorder__title {
  white-space: nowrap;
}
.lieveamsterdammer__sortorder__link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  text-underline-position: under;
  white-space: nowrap;
  transition: color 300ms;
}
.lieveamsterdammer__sortorder__link.selected {
  color: var(--color-red);
}
.lieveamsterdammer__sortorder__link:hover, .lieveamsterdammer__sortorder__link:focus {
  color: var(--color-red);
  text-decoration: underline;
}

.lieveamsterdammer__galery {
  margin-bottom: clamp(30px, 6dvw, 60px);
}

.lieveamsterdammer__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
.lieveamsterdammer__list > li {
  padding-left: 24px;
  flex: none;
  width: 50%;
}
.lieveamsterdammer__list > li .lieveamsterdammer__item {
  min-height: calc(100% - 24px);
}
@media (max-width: 900px) {
  .lieveamsterdammer__list {
    display: block;
  }
  .lieveamsterdammer__list > li {
    width: 100%;
  }
}