.widget-ctas {
  margin-bottom: 50px;
}
.widget-ctas .widget-ctas__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: clamp(20px, 2.5dvw, 25px) 12px;
}
.widget-ctas__listholder {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #fff;
}
@media (max-width: 620px) {
  .widget-ctas__listholder {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.widget-ctas--grid .widget-ctas__list {
  flex-wrap: wrap;
}
.widget-ctas--grid .widget-ctas__item {
  margin-bottom: 24px;
}
.widget-ctas__item {
  flex: none;
  width: 33.333%;
  padding: 0 12px;
  min-height: 405px;
}
@media (max-width: 1304px) {
  .widget-ctas__item {
    min-height: calc(33.33dvw - 24px);
  }
}
.widget-ctas__item__content {
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(102, 102, 102, 0.2);
  border: 1px solid #e3e3e3;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: column;
}
.widget-ctas__item__image {
  flex: none;
  font-family: var(--font-heading);
  position: relative;
  color: #fff;
  line-height: 120%;
}
.widget-ctas__item__image::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.widget-ctas__item__image > img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-ctas__item__text {
  flex: 1;
  padding: 20px 17px 15px;
}
.widget-ctas__item__text h3 {
  text-transform: uppercase;
}
.widget-ctas__item__text p {
  font-size: 15px;
  color: var(--color-grey-dark6a);
}
.widget-ctas__item__text > *:last-child {
  margin-bottom: 0;
}
.widget-ctas__item__buttonholder {
  flex: none;
  padding: 20px 17px;
}
.widget-ctas__list--2 .widget-ctas__item {
  width: 50%;
  min-height: calc(33dvw - 24px);
}
@media (min-width: 1304px) {
  .widget-ctas__list--2 .widget-ctas__item {
    min-height: 430px;
  }
}
@media (max-width: 900px) {
  .widget-ctas__item {
    width: 50%;
    min-height: calc(50dvw - 24px);
  }
}
@media (max-width: 600px) {
  .widget-ctas__item {
    width: 100% !important;
    min-height: 80px !important;
  }
}
.widget-ctas__itemsnav {
  margin-bottom: 15px;
}