.widget-3steps {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0 7px 29px rgba(0, 0, 0, 0.1);
  padding: 50px 15px 24px;
}
.widget-3steps__content, .widget-3steps__list {
  display: flex;
  align-items: flex-start;
}
.widget-3steps__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
  counter-reset: numlist;
}
.widget-3steps__arrowleft, .widget-3steps__arrowright {
  position: absolute;
  top: 100%;
  margin-top: -30px;
}
.widget-3steps__arrowleft {
  left: -10px;
}
.widget-3steps__arrowright {
  right: 15px;
}
.widget-3steps__step {
  padding: 0 15px;
  position: relative;
  font-family: var(--font-intro);
  font-style: italic;
  padding-top: 45px;
}
.widget-3steps__step::before {
  display: inline-block;
  counter-increment: numlist;
  content: counter(numlist, decimal);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -10px);
  font-size: 16px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: var(--color-red);
  border-radius: 50%;
  color: #fff;
}
.widget-3steps__step--gold::before {
  background-color: var(--color-gold);
}
.widget-3steps__step--yellow::before {
  background-color: var(--color-yellow);
}
.widget-3steps__step > p {
  line-height: 125%;
}
.widget-3steps__step--red p > b {
  color: var(--color-red);
}
.widget-3steps__step--gold p > b {
  color: var(--color-gold);
}
.widget-3steps__step--yellow p > b {
  color: var(--color-yellow);
}
.widget-3steps__thumbleft, .widget-3steps__thumbright {
  flex: none;
  width: clamp(100px, 13dvw, 176px);
  position: relative;
}
.widget-3steps__hartjes {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: auto;
  pointer-events: none;
}
.widget-3steps__thumbleft__image {
  width: 100%;
  height: auto;
}
.widget-3steps__thumbright__image {
  width: 88%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.widget-3steps__footer {
  text-align: center;
}
.widget-3steps__footer a {
  font-family: var(--font-intro);
  font-style: italic;
  text-underline-position: under;
}
@media (max-width: 1000px) {
  .widget-3steps__thumbleft, .widget-3steps__arrowleft {
    display: none;
  }
}
@media (max-width: 800px) {
  .widget-3steps__content, .widget-3steps__list {
    flex-flow: column;
    align-items: center;
  }
  .widget-3steps__step {
    max-width: 320px;
  }
  .widget-3steps__arrowright {
    transform: scale(-1, 1) rotate(60deg);
    margin: 5px;
    right: 0;
  }
  .widget-3steps__thumbright {
    width: 176px;
    margin-bottom: 30px;
  }
}