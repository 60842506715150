.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

:root {
  --wh-field-textinput-height: 48px;
  --wh-field-textarea-height: 140px;
  --wh-field-radiocheckbox-size: 24px;
  --wh-label-font-size: 16px;
  --wh-label-width: 190px;
  --wh-button-background-color: #F1F1F1;
  --wh-button-border: 1px solid #BFBFBF;
  --wh-button-color: var(--color-grey-dark76);
  --wh-button-padding: 12px 15px;
  --wh-button-border-radius: 4px;
  --wh-button-height: 48px;
  --wh-field-font-size: 18px;
  --wh-field-padding: 12px 15px;
  --wh-field-border-radius: 3px;
  --wh-field-color: #3a3a3a;
  --wh-field-color--hover: inherit;
  --wh-field-color--focus: inherit;
  --wh-field-color--error: var(--color-red);
  --wh-field-color--disabled: #C5C5C5;
  --wh-field-border-color: var(--color-divider);
  --wh-field-border-color--hover: var(--color-grey-dark);
  --wh-field-border-color--focus: var(--color-grey-dark);
  --wh-field-border-color--error: var(--color-red);
  --wh-field-border-color--disabled: var(--color-grey-light);
  --wh-field-background-color: #fff;
  --wh-field-background-color--hover: #fff;
  --wh-field-background-color--focus: #fff;
  --wh-field-background-color--error: #fff;
  --wh-field-background-color--disabled: var(--color-grey-lighter);
  --wh-field-border-box-shadow: none;
  --wh-field-border-box-shadow--hover: 0 0 0 4px rgba(30, 17, 127, 0.15);
  --wh-field-border-box-shadow--focus: 0 0 0 4px rgba(30, 17, 127, 0.15);
  --wh-field-border-box-shadow--error: none;
  --wh-field-border-box-shadow--disabled: none;
  --wh-imgedit-padding: 4px;
  --wh-imgedit-margin: 2px 3px;
  --wh-imgedit-width: 150px;
  --wh-imgedit-height: 200px;
  --wh-imgedit-explain-padding: 5px;
  --wh-imgedit-uploadicon-width: 50px;
  --wh-imgedit-uploadicon-height: 50px;
  --wh-imgedit-uploadicon-margin: 0 0 20px;
  --wh-fileedit-name-margin-right: 15px;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: var(--color-grey-dark76);
}

::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-dark76);
}

:-ms-input-placeholder {
  opacity: 1;
  color: var(--color-grey-dark76);
}

input:-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-dark76);
}

input:disabled::-webkit-input-placeholder {
  opacity: 0.8;
}

input:disabled::-moz-placeholder {
  opacity: 0.8;
}

input:disabled:-ms-input-placeholder {
  opacity: 0.8;
}

input:disabledinput:-moz-placeholder {
  opacity: 0.8;
}

.wh_form__requirednote {
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}
.wh_form__requirednote__icon {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  color: var(--color-red);
}

.wh-form__fieldgroup .wh-form__button {
  font-weight: 400;
}

.wh-form__richtext > *:last-child {
  margin-bottom: 0;
}

.wh-form__group--borderbottom {
  padding-bottom: clamp(30px, 5dvw, 50px);
  border-bottom: 1px solid var(--color-divider);
}

.wh-form__group + .wh-form__group {
  margin-top: clamp(30px, 5dvw, 50px);
}

@media (min-width: 400px) {
  .fieldgroupwrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .fieldgroupwrapper > .wh-form__fieldgroup:first-child {
    margin-right: 20px;
    /*
        @media(max-width: 800px)
        {
          & > .wh-form__label
          {
            width: auto !important;
            white-space: nowrap;
          }
        }
           */
  }
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".street"],
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".city"] {
    flex: 1;
  }
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".nr_detail"],
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".zip"] {
    flex: none;
  }
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".nr_detail"] .wh-form__fieldline,
  .fieldgroupwrapper .wh-form__fieldgroup[data-wh-form-group-for$=".zip"] .wh-form__fieldline {
    width: 120px;
  }
  .fieldgroupwrapper > .wh-form__fieldgroup + .wh-form__fieldgroup .wh-form__fields {
    max-width: none;
  }
  .fieldgroupwrapper > .wh-form__fieldgroup + .wh-form__fieldgroup > .wh-form__label {
    width: auto !important;
    white-space: nowrap;
  }
}
@media (max-width: 800px) {
  .fieldgroupwrapper > .wh-form__fieldgroup:first-child > .wh-form__label {
    width: auto !important;
    white-space: nowrap;
  }
}

.wh-form__fieldgroup--group {
  margin-bottom: 0 !important;
}

/* infotext below input */
.wh-form__infotextwrapper {
  width: 100%;
  outline: none;
}
.wh-form__infotextwrapper p, .wh-form__infotextwrapper h2 {
  margin-bottom: 5px;
}
.wh-form__infotextwrapper .wh-form__infotext {
  font-size: clamp(14px, 1.6dvw, 16px);
  padding: 10px 0;
  width: 100%;
  font-style: italic;
}
.wh-form__infotextwrapper .wh-form__infotext > *:last-child {
  margin-bottom: 0;
}

.wh-form__fieldline--nowrap.wh-form__fieldline--hasinfotext {
  flex-wrap: wrap;
}

.wh-form__optiondata + .wh-form__infotextwrapper {
  margin-top: 10px;
  flex: 0 0 100%;
}

.wh-form__footnote {
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.wh-form__twocols {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.wh-form__twocols > div {
  flex: 1 1 50%;
  max-width: calc(50% - 15px);
}
@media (max-width: 550px) {
  .wh-form__twocols {
    display: block;
  }
  .wh-form__twocols > div {
    max-width: 100%;
  }
}

.wh-styledinput {
  font-size: inherit;
}
.wh-styledinput .wh-anchor {
  scroll-margin-top: 120px;
}
.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid var(--color-grey-light);
  margin: 30px 0 25px;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: none;
  font-weight: 700;
  width: 190px;
  padding-right: clamp(15px, 2.5dvw, 25px);
  margin: 0 0 0;
  font-size: inherit;
  color: inherit;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 190px);
}
.wh-styledinput .webshop-checkout__shippingpayment .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .webshop-checkout__totalamount {
  margin-top: 15px;
}
@media (max-width: 800px) {
  .wh-styledinput .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    position: relative;
  }
  .wh-styledinput .wh-form__fieldgroup--heading {
    margin-bottom: 0;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 10px;
    padding-right: 0;
  }
  .wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none !important;
    max-width: 100% !important;
    min-width: 0 !important;
  }
  .wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: inherit;
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: inherit;
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 16px;
  line-height: 140%;
}
.wh-styledinput .wh-form__optionlabel a {
  color: inherit;
  text-underline-position: under;
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .webshop-checkout__shippingpayment h2.wh-form__grouptitle {
  margin: 15px 0 5px;
  font-size: clamp(20px, 2.4vw, 26px);
}
.wh-styledinput .wh-form__fieldgroup {
  margin-top: clamp(15px, 2.5dvw, 25px);
}
.wh-styledinput .wh-form__fieldgroup.address-country--hide {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  font-weight: 700;
  font-size: 16px;
}
.wh-styledinput .wh-form__fieldgroup.wh-form__fieldgroup--label-in-field > .wh-form__label {
  top: 27px;
  font-size: 16px;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label:empty,
.wh-styledinput .wh-form__fieldgroup > .wh-form__label[hidden] {
  display: none;
}
.wh-styledinput .wh-form__fields, .wh-styledinput .wh-form__label {
  font-size: 18px;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea {
  min-height: 48px;
  padding: 14px 15px;
  flex: 1;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__imgedit,
.wh-styledinput .datetime__time,
.wh-styledinput .wh-form__uploadfieldfilename {
  border: 1px solid var(--color-grey-dark25);
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  color: var(--color-grey-dark25);
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__imgedit:disabled,
.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-styledinput .datetime__time:disabled,
.wh-styledinput .datetime__time[data-wh-form-disabled],
.wh-styledinput .wh-form__uploadfieldfilename:disabled,
.wh-styledinput .wh-form__uploadfieldfilename[data-wh-form-disabled] {
  color: #666;
  border-color: var(--color-grey-light);
  background-color: var(--color-grey-lighter);
  box-shadow: none;
}
.wh-styledinput input:focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-styledinput textarea:focus-within,
.wh-styledinput .wh-form__imgedit:focus-within,
.wh-styledinput .datetime__time:focus-within,
.wh-styledinput .wh-form__uploadfieldfilename:focus-within {
  border-color: var(--color-grey-dark);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
  background-color: #FFFFFF;
}
.wh-styledinput input:not(:disabled):focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):focus,
.wh-styledinput textarea:not(:disabled):focus,
.wh-styledinput .wh-form__imgedit:not(:disabled):focus,
.wh-styledinput .datetime__time:not(:disabled):focus,
.wh-styledinput .wh-form__uploadfieldfilename:not(:disabled):focus {
  border-color: var(--color-grey-dark);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
  background-color: #FFFFFF;
}
.wh-styledinput input:not(:disabled):hover, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):hover,
.wh-styledinput textarea:not(:disabled):hover,
.wh-styledinput .wh-form__imgedit:not(:disabled):hover,
.wh-styledinput .datetime__time:not(:disabled):hover,
.wh-styledinput .wh-form__uploadfieldfilename:not(:disabled):hover {
  border-color: var(--color-grey-dark);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
}
.wh-styledinput textarea {
  min-height: 140px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline input, .wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline select,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline textarea,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .wh-form__imgedit,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .datetime__time {
  border-color: var(--color-red);
}
.wh-styledinput .wh-form__fieldline--example {
  margin-top: 7px;
}
.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  font-weight: 400;
  width: 100%; /* force error text below item */
  color: var(--color-red);
  padding: 8px 0 0;
  font-size: 14px;
  line-height: 165%;
}
.wh-styledinput .wh-form__error::before {
  content: "\f071";
  display: inline-block;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__error,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__error {
  display: block;
}