.widget-faq {
  margin-top: 50px;
  margin-bottom: 50px;
}
.widget-faq__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-divider);
}
.widget-faq__item {
  border-bottom: 1px solid var(--color-divider);
}
.widget-faq .wh-details__container__inner {
  padding: 0 0 24px;
}
.widget-faq .wh-details__summary {
  padding: 10px 0;
  display: flex;
  align-items: baseline;
}
.widget-faq .wh-details__summary h3 {
  flex: 1;
  line-height: 130%;
  font-size: clamp(21px, 2.7dvw, 27px);
  margin-bottom: 0;
}
.widget-faq .wh-details__summary::after {
  flex: none;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  padding: 0 25px;
  color: var(--color-red);
  content: "\f078";
}
html.theme-gold .widget-faq .wh-details__summary::after {
  color: var(--color-gold);
}
.widget-faq .wh-details--open > .wh-details__summary::after {
  content: "\f077";
}