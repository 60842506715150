.widget-photogrid {
  margin-bottom: 50px;
}
.widget-photogrid__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 10px;
}
.widget-photogrid__item {
  height: clamp(100px, 20dvw, 200px);
  flex: none;
  overflow: hidden;
  border-top: 10px solid #fff;
  border-left: 10px solid #fff;
}
.widget-photogrid__item[data-row="0"] {
  border-top: 0 none;
}
.widget-photogrid__item[data-col="0"] {
  border-left: 0 none;
}
.widget-photogrid__item img {
  width: 100%;
  height: 100%;
  transform-origin: center;
  object-fit: cover;
  object-position: center;
  vertical-align: bottom;
  cursor: pointer;
  transition: transform 0.3s;
}
.widget-photogrid__item img:hover {
  transform: scale(1.05);
}