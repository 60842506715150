.pagination-morebutton {
  margin: 0 auto;
  display: flex;
  height: clamp(48px, 6.6vw, 50px);
  position: relative;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid var(--color-red);
}
.pagination-morebuttonwrapper {
  border-top: 1px solid var(--color-divider);
  margin-top: clamp(50px, 9vw, 90px);
}
.pagination-morebutton:hover {
  background-color: var(--color-grey-lighter);
}